/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.0.9 gallery_wip.gltf --transform -k -K
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Cartouches() {
    const { nodes, materials } = useGLTF( '/gallery_wip-transformed.glb' )
    return <>
        <group name="Cartouche_01" position={[-9.9, 1.24, -18.92]}>
            <mesh name="Cartouche_01_1" geometry={nodes.Cartouche_01_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_01_2" geometry={nodes.Cartouche_01_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_02" position={[-2.4, 1.24, -18.92]}>
            <mesh name="Cartouche_02_1" geometry={nodes.Cartouche_02_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_02_2" geometry={nodes.Cartouche_02_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_03" position={[5.1, 1.24, -18.92]}>
            <mesh name="Cartouche_03_1" geometry={nodes.Cartouche_03_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_03_2" geometry={nodes.Cartouche_03_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_04" position={[12.6, 1.25, -18.92]}>
            <mesh name="Cartouche_04_1" geometry={nodes.Cartouche_04_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_04_2" geometry={nodes.Cartouche_04_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_30" position={[7.21, 1.39, 2.3]}>
            <mesh name="Cartouche_30_1" geometry={nodes.Cartouche_30_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_30_2" geometry={nodes.Cartouche_30_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_05" position={[15.34, 1.4, -7.42]}>
            <mesh name="Cartouche_05_1" geometry={nodes.Cartouche_05_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_05_2" geometry={nodes.Cartouche_05_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_06" position={[15.34, 1.4, -1.52]}>
            <mesh name="Cartouche_06_1" geometry={nodes.Cartouche_06_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_06_2" geometry={nodes.Cartouche_06_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_07" position={[15.34, 1.4, 3.09]}>
            <mesh name="Cartouche_07_1" geometry={nodes.Cartouche_07_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_07_2" geometry={nodes.Cartouche_07_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_08" position={[15.34, 1.4, 7.39]}>
            <mesh name="Cartouche_08_1" geometry={nodes.Cartouche_08_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_08_2" geometry={nodes.Cartouche_08_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_09" position={[15.34, 1.4, 14.39]}>
            <mesh name="Cartouche_09_1" geometry={nodes.Cartouche_09_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_09_2" geometry={nodes.Cartouche_09_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_10" position={[-2.84, 1.07, 17.07]}>
            <mesh name="Cartouche_10_1" geometry={nodes.Cartouche_10_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_10_2" geometry={nodes.Cartouche_10_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_11" position={[-14.65, 1.4, 12.92]}>
            <mesh name="Cartouche_11_1" geometry={nodes.Cartouche_11_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_11_2" geometry={nodes.Cartouche_11_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_12" position={[-14.65, 1.4, 8.38]}>
            <mesh name="Cartouche_11_1" geometry={nodes.Cartouche_11_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_11_2" geometry={nodes.Cartouche_11_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_13" position={[-14.65, 1.25, 3.61]}>
            <mesh name="Cartouche_13_1" geometry={nodes.Cartouche_13_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_13_2" geometry={nodes.Cartouche_13_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_14" position={[-14.65, 1.25, -1.53]}>
            <mesh name="Cartouche_14_1" geometry={nodes.Cartouche_14_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_14_2" geometry={nodes.Cartouche_14_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_15" position={[-14.65, 1.39, -6.6]}>
            <mesh name="Cartouche_15_1" geometry={nodes.Cartouche_15_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_15_2" geometry={nodes.Cartouche_15_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_16" position={[-14.65, 1.4, -11.74]}>
            <mesh name="Cartouche_16_1" geometry={nodes.Cartouche_16_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_16_2" geometry={nodes.Cartouche_16_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_17" position={[-9.66, 1.24, -5.65]}>
            <mesh name="Cartouche_17_1" geometry={nodes.Cartouche_17_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_17_2" geometry={nodes.Cartouche_17_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_18" position={[-6.51, 1.39, -5.65]}>
            <mesh name="Cartouche_18_1" geometry={nodes.Cartouche_18_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_18_2" geometry={nodes.Cartouche_18_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_19" position={[5.34, 1.24, -5.68]}>
            <mesh name="Cartouche_19_1" geometry={nodes.Cartouche_19_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_19_2" geometry={nodes.Cartouche_19_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_20" position={[8.49, 1.39, -5.68]}>
            <mesh name="Cartouche_20_1" geometry={nodes.Cartouche_20_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_20_2" geometry={nodes.Cartouche_20_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_21" position={[10.2, 1.39, -5.2]}>
            <mesh name="Cartouche_21_1" geometry={nodes.Cartouche_21_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_21_2" geometry={nodes.Cartouche_21_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_28" position={[8.34, 1.24, 1.85]}>
            <mesh name="Cartouche_28_1" geometry={nodes.Cartouche_28_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_28_2" geometry={nodes.Cartouche_28_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_27" position={[5.49, 1.4, 1.85]}>
            <mesh name="Cartouche_27_1" geometry={nodes.Cartouche_27_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_27_2" geometry={nodes.Cartouche_27_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_26" position={[-6.66, 1.24, 1.85]}>
            <mesh name="Cartouche_26_1" geometry={nodes.Cartouche_26_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_26_2" geometry={nodes.Cartouche_26_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_25" position={[-9.51, 1.4, 1.85]}>
            <mesh name="Cartouche_25_1" geometry={nodes.Cartouche_25_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_25_2" geometry={nodes.Cartouche_25_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_22" position={[7.35, 1.25, -5.2]}>
            <mesh name="Cartouche_22_1" geometry={nodes.Cartouche_22_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_22_2" geometry={nodes.Cartouche_22_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_23" position={[-4.8, 1.39, -5.2]}>
            <mesh name="Cartouche_23_1" geometry={nodes.Cartouche_23_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_23_2" geometry={nodes.Cartouche_23_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_24" position={[-7.65, 1.24, -5.2]}>
            <mesh name="Cartouche_24_1" geometry={nodes.Cartouche_24_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_24_2" geometry={nodes.Cartouche_24_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_29" position={[10.36, 1.25, 2.3]}>
            <mesh name="Cartouche_29_1" geometry={nodes.Cartouche_29_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_29_2" geometry={nodes.Cartouche_29_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_31" position={[-4.64, 1.24, 2.3]}>
            <mesh name="Cartouche_31_1" geometry={nodes.Cartouche_31_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_31_2" geometry={nodes.Cartouche_31_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_32" position={[-7.8, 1.4, 2.3]}>
            <mesh name="Cartouche_32_1" geometry={nodes.Cartouche_32_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_32_2" geometry={nodes.Cartouche_32_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_33" position={[-9.67, 1.24, 9.35]}>
            <mesh name="Cartouche_33_1" geometry={nodes.Cartouche_33_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_33_2" geometry={nodes.Cartouche_33_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_34" position={[-6.51, 1.4, 9.35]}>
            <mesh name="Cartouche_34_1" geometry={nodes.Cartouche_34_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_34_2" geometry={nodes.Cartouche_34_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_35" position={[5.34, 1.24, 9.35]}>
            <mesh name="Cartouche_35_1" geometry={nodes.Cartouche_35_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_35_2" geometry={nodes.Cartouche_35_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_36" position={[8.48, 1.39, 9.35]}>
            <mesh name="Cartouche_36_1" geometry={nodes.Cartouche_36_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_36_2" geometry={nodes.Cartouche_36_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_37" position={[10.21, 1.39, 9.8]}>
            <mesh name="Cartouche_37_1" geometry={nodes.Cartouche_37_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_37_2" geometry={nodes.Cartouche_37_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_38" position={[7.36, 1.25, 9.8]}>
            <mesh name="Cartouche_38_1" geometry={nodes.Cartouche_38_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_38_2" geometry={nodes.Cartouche_38_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_39" position={[-4.79, 1.39, 9.8]}>
            <mesh name="Cartouche_39_1" geometry={nodes.Cartouche_39_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_39_2" geometry={nodes.Cartouche_39_2.geometry} material={materials.mat_cartouche_content} />
        </group>
        <group name="Cartouche_40" position={[-7.64, 1.25, 9.8]}>
            <mesh name="Cartouche_40_1" geometry={nodes.Cartouche_40_1.geometry} material={materials.mat_cartouche_frame} />
            <mesh name="Cartouche_40_2" geometry={nodes.Cartouche_40_2.geometry} material={materials.mat_cartouche_content} />
        </group>
    </>
}