import { useEffect, useRef, useState } from "react";
import "../MenuCanvas/MenuCanvas.css"
import UsePaintingIndex from "../../store/UsePaintingIndex";
import useBearStore from "../../store/useBearStore";
import Tooltip from "../ToolTip/ToolTip";
import IsMobileDevice from "../../utils/IsMobileDevice";
import TooltipMobile from "../ToolTip/ToolTipMobile";
import isMobileDevice from "../../utils/IsMobileDevice";

export default function MenuCanvas( { cameraControlsRef, setIsHover } ) {

    const [style, setStyle] = useState()

    const bears = useBearStore( ( state ) => state.bears )

    const inc = useBearStore( ( state ) => state.inc )

    var mobile = IsMobileDevice()
    // Bouton qui incrémente les index //
    const index = UsePaintingIndex( ( state ) => state.index )

    const setIndex = UsePaintingIndex( ( state ) => state.setIndex )

    const increamentIndex = UsePaintingIndex( ( state ) => state.setIndexForward )

    const decrementIndex = UsePaintingIndex( ( state ) => state.setIndexBackward )

    // ------------------------------------------------------------------------ //
    // Position prédéfinis devant les tableau ainsi que leurs rotations         //
    // ----------------------------------------------------------------------- //

    var tabPos =
        [
            [-10.9, 2.0, -15.9], // Tableau 1
            [-3.4, 2, -15.9],   // Tableau 2
            [4.1, 2, -15.9],    // Tableau 3
            [11.5, 2, -15.9],   // Tableau 4
            [12.3, 2, -8.3],    // Tableau 5
            [9.3, 2, -8.6],     // Tableau 20
            [6.3, 2, -8.6],     // Tableau 19
            [-5.7, 2, -8.6],    // Tableau 18
            [-8.6, 2, -8.6],    // Tableau 17
            [-8.6, 2, -2.18],   // Tableau 24
            [-5.6, 2, -2.19],   // Tableau 23
            [6.3, 2, -2.18],    // Tableau 22
            [9.3, 2, -2.18],    // Tableau 21
            [12.3, 2, -2.3],    // Tableau 6
            [9.3, 2, -1.16],    // Tableau 28
            [6.3, 2, -1.16],    // Tableau 27
            [-5.6, 2, -1.16],   // Tableau 26
            [-8.6, 2, -1.16],   // Tableau 25
            [-8.6, 2, 5.3],     // Tableau 32
            [-5.6, 2, 5.3],     // Tableau 31
            [6.3, 2, 5.3],      // Tableau 30
            [9.3, 2, 5.3],      // Tableau 29
            [12.3, 2, 2],       // Tableau 7
            [12.3, 2, 6.5],     // Tableau 8
            [9.3, 2, 6.3],      // Tableau 36
            [6.3, 2, 6.3],      // Tableau 35
            [-5.6, 2, 6.3],     // Tableau 34
            [-8.6, 2, 6.3],     // Tableau 33
            [-8.6, 2, 12.8],    // Tableau 40
            [-5.6, 2, 12.8],    // Tableau 39
            [6.3, 2, 12.8],     // Tableau 38
            [9.3, 2, 12.8],     // Tableau 37
            [12.3, 2, 13.5],    // Tableau 9
            [0.3, 2, 7],        // Tableau 10
            [-11.6, 2, 13.7],   // Tableau 11
            [-11.6, 2, 9.2],    // Tableau 12
            [-11.6, 2, 4.6],    // Tableau 13
            [-11.6, 2, -0.52],  // Tableau 14
            [-11.6, 2, -5.7],   // Tableau 15
            [-11.6, 2, -10.8],  // Tableau 16

        ]

    var sidePos =
        [
            [0, 1.57], // Tableau 1 
            [0, 1.56],  // Tableau 2
            [0, 1.57], // Tableau 3
            [0, 1.57], // Tableau 4
            [-1.57, 1.57], // Tableau 5
            [Math.PI, 1.57], // Tableau 20
            [Math.PI, 1.57], // Tableau 19
            [Math.PI, 1.57], // Tableau 18
            [Math.PI, 1.57], // Tableau 17
            [0, 1.57], // Tableau 24
            [0, 1.57], // Tableau 23
            [0, 1.57], // Tableau 22
            [0, 1.57], // Tableau 21
            [-1.57, 1.57], // Tableau 6
            [Math.PI, 1.57], // Tableau 28
            [Math.PI, 1.57], // Tableau 27
            [Math.PI, 1.57], // Tableau 26
            [Math.PI, 1.57], // Tableau 25
            [0, 1.57], // Tableau 32
            [0, 1.57], // Tableau 31
            [0, 1.57], // Tableau 30
            [0, 1.57], // Tableau 29
            [-1.57, 1.57], // Tableau 7
            [-1.57, 1.57], // Tableau 8
            [Math.PI, 1.57], // Tableau 36
            [Math.PI, 1.57], // Tableau 35
            [Math.PI, 1.57], // Tableau 34
            [Math.PI, 1.57], // Tableau 33
            [0, 1.57], // Tableau 40
            [0, 1.57], // Tableau 39
            [0, 1.57], // Tableau 38
            [0, 1.57], // Tableau 37
            [-1.57, 1.57], // Tableau 9
            [Math.PI, 1.57], // Tableau 10
            [Math.PI / 2, 1.57], // Tableau 11
            [Math.PI / 2, 1.57], // Tableau 12
            [Math.PI / 2, 1.57], // Tableau 13
            [Math.PI / 2, 1.57], // Tableau 14
            [Math.PI / 2, 1.57], // Tableau 15
            [Math.PI / 2, 1.57], // Tableau 16
        ]


    useEffect( () => {
        if ( mobile ) {
            setStyle( { display: 'none' } )
        }
        else {
            setStyle( { position: 'absolute', top: '1rem', right: '1rem', borderRadius: '50%', backgroundColor: 'white' } )
        }
    }, [mobile] )
    useEffect( () => {
        function handleKeyDown( event ) {
            if ( event.keyCode === 37 && bears === 'true' ) {
                decrementIndex()
                if ( index - 1 === -1 ) {
                    setIndex( 39 )
                    cameraControlsRef.current.moveTo( tabPos[39][0], tabPos[39][1], tabPos[39][2], true )
                    cameraControlsRef.current.rotateTo( sidePos[39][0], sidePos[39][1], true )
                }
                else {
                    cameraControlsRef.current.moveTo( tabPos[index - 1][0], tabPos[index - 1][1], tabPos[index - 1][2], true )
                    cameraControlsRef.current.rotateTo( sidePos[index - 1][0], sidePos[index - 1][1], true )
                }
            }
            if ( event.keyCode === 39 && bears === 'true' ) {
                increamentIndex()
                if ( index + 1 === 40 ) {
                    setIndex( 0 )
                    cameraControlsRef.current.moveTo( tabPos[0][0], tabPos[0][1], tabPos[0][2], true )
                    cameraControlsRef.current.rotateTo( sidePos[0][0], sidePos[0][1], true )
                }
                else {
                    cameraControlsRef.current.moveTo( tabPos[index + 1][0], tabPos[index + 1][1], tabPos[index + 1][2], true )
                    cameraControlsRef.current.rotateTo( sidePos[index + 1][0], sidePos[index + 1][1], true )
                }
            }
        }

        document.addEventListener( 'keydown', handleKeyDown );

        return () => {
            document.removeEventListener( 'keydown', handleKeyDown );
        };
    } );

    // Jouer le son 
    const [isPlaying, setIsPlaying] = useState( false );
    const [isShown, setIsShown] = useState( false );
    const audioRef = useRef( null );

    const handleButtonClick = () => {
        setIsShown( !isShown );
        if ( isPlaying ) {
            console.log( audioRef.current )
            audioRef.current.pause();
            setIsPlaying( false );
        } else {
            audioRef.current.play();
            setIsPlaying( true );
        }

    };

    useEffect( () => {
        if ( audioRef.current && isShown ) {
            const audioElement = audioRef.current;
            const playPromise = audioElement.play();
            if ( playPromise !== undefined ) {
                playPromise.then( () => {
                    audioElement.pause();
                } );
            }
        }
    }, [] );

    return <>

        {bears === 'true' &&
            <div className={mobile ? "canvasMenu--mobile" : "canvasMenu"}>
                <Tooltip tooltip={<img className="icones" style={style} ></img>}>
                    <img
                        className="canvasMenu__nextButton"
                        src="/Icons/icon-next-active.svg"
                        onClick={( e ) => {
                            e.stopPropagation()
                            increamentIndex()
                            if ( index + 1 === 40 ) {
                                setIndex( 0 )
                                cameraControlsRef.current.moveTo( tabPos[0][0], tabPos[0][1], tabPos[0][2], true )
                                cameraControlsRef.current.rotateTo( sidePos[0][0], sidePos[0][1], true )
                            }
                            else {
                                cameraControlsRef.current.moveTo( tabPos[index + 1][0], tabPos[index + 1][1], tabPos[index + 1][2], true )
                                cameraControlsRef.current.rotateTo( sidePos[index + 1][0], sidePos[index + 1][1], true )
                            }
                        }
                        }

                        onPointerOut={( e ) => {
                            setIsHover( true )
                        }}
                        onPointerOver={( e ) => {
                            setIsHover( false )
                        }}
                    >
                    </img>
                </Tooltip>
                <Tooltip tooltip={<img className="icones"  style={style} ></img>}>
                    <img
                        className={mobile ? "canvasMenu__previousButton--mobile" : "canvasMenu__previousButton"}
                        src="/Icons/icon-previous-active.svg"
                        onClick={() => {
                            decrementIndex()
                            if ( index - 1 === -1 ) {
                                setIndex( 39 )
                                cameraControlsRef.current.moveTo( tabPos[39][0], tabPos[39][1], tabPos[39][2], true )
                                cameraControlsRef.current.rotateTo( sidePos[39][0], sidePos[39][1], true )
                            }
                            else {
                                cameraControlsRef.current.moveTo( tabPos[index - 1][0], tabPos[index - 1][1], tabPos[index - 1][2], true )
                                cameraControlsRef.current.rotateTo( sidePos[index - 1][0], sidePos[index - 1][1], true )
                            }
                        }}
                        onPointerOut={( e ) => {
                            setIsHover( true )
                        }}
                        onPointerOver={( e ) => {
                            setIsHover( false )
                        }}
                    >
                    </img>
                </Tooltip>

            </div>
        }
        <div className="audioMenu">
            {!isShown && (


                <img className="burgerMenu__icones" onClick={handleButtonClick} src="/Icons/icon-on.svg"></img>

            )}
            {isShown && (


                <img className="burgerMenu__icones" onClick={handleButtonClick} src="/Icons/icon-audio-off.svg"></img>

            )}
            <div>
                <audio ref={audioRef} loop>
                    <source src="/sing.mp3" type="audio/mp3" />
                    Votre navigateur ne prend pas en charge l'élément audio.
                </audio>
            </div>
        </div>
    </>
}