/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.0.9 gallery_wip.gltf --transform -k -K
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useMemo } from 'react'
import * as THREE from 'three'
import { TextureLoader } from 'three'
import { useLoader } from '@react-three/fiber'

export function Gallery( props ) {
  const { nodes, materials } = useGLTF( '/gallery_wip-transformed.glb' )


  const [colorMap, texture, normal, texture2, normal2] = useLoader( TextureLoader, [
    "parquet.png",
    "Wall/Textures/White_Wall.jpg",
    "Wall/Textures/White_Wall_NORMAL.jpg",
    "Wall/Textures/White_Wall.jpg",
    "Wall/Textures/White_Wall_NORMAL.jpg",
  ] )

  const size = 20;

  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( size, size );

  normal.wrapS = THREE.RepeatWrapping;
  normal.wrapT = THREE.RepeatWrapping;
  normal.repeat.set( size, size );

  texture2.wrapS = THREE.RepeatWrapping;
  texture2.wrapT = THREE.RepeatWrapping;
  texture2.repeat.set( size, size );

  normal2.wrapS = THREE.RepeatWrapping;
  normal2.wrapT = THREE.RepeatWrapping;
  normal2.repeat.set( size, size );

  colorMap.wrapS = THREE.RepeatWrapping
  colorMap.wrapT = THREE.RepeatWrapping
  colorMap.repeat.set( 10, 10 )

  return (
    <group {...props} dispose={null}
    >
      <group name="Scene" >
        <mesh name="Floor_4K" geometry={nodes.Floor_4K.geometry}
          receiveShadow >
          <meshPhysicalMaterial map={colorMap}
            roughness={1}
          />
        </mesh>
        <mesh
          name="Roof"
          geometry={nodes.Roof.geometry}
          castShadow
        >
          <meshPhysicalMaterial
            attach="material"
            roughness={0.8}
          >
            <primitive attach="map" object={texture} />
            <primitive attach="normalMap" object={normal} />
          </meshPhysicalMaterial>
        </mesh>
        <mesh name="Roof_Plinthe" geometry={nodes.Roof_Plinthe.geometry} material={materials.mat_roof} castShadow >
          <meshPhysicalMaterial color='whitesmoke'>

          </meshPhysicalMaterial>
        </mesh>
        <mesh name="Walls_Plinthes" geometry={nodes.Walls_Plinthes.geometry} material={materials.mat_walls_plinthe} >
          <meshPhysicalMaterial
            attach="material"
            roughness={0.8}
          >
            <primitive attach="map" object={texture} />
            <primitive attach="normalMap" object={normal} />
          </meshPhysicalMaterial>
        </mesh>
        <mesh
          name="Walls"
          geometry={nodes.Walls.geometry}
          userData={{ name: 'Walls' }}
        >
          <meshPhysicalMaterial
            attach="material"
            roughness={0.8}
          >
            <primitive attach="map" object={texture} />
            <primitive attach="normalMap" object={normal} />
          </meshPhysicalMaterial>
        </mesh>
        <group name="Side_Deco_R_01">
          <mesh name="Side_Deco_R_01_1" geometry={nodes.Side_Deco_R_01_1.geometry} >
            <meshPhysicalMaterial
              attach="material"
              roughness={0.8}
            >
              <primitive attach="map" object={texture} />
              <primitive attach="normalMap" object={normal} />
            </meshPhysicalMaterial>

          </mesh>
          <mesh name="Side_Deco_R_01_2" geometry={nodes.Side_Deco_R_01_2.geometry} material={materials.mat_walls_plinthe} />
        </group>
        <group name="Side_Deco_R_02" position={[-0.01, 0, 0]}>
          <mesh name="Side_Deco_R_02_1" geometry={nodes.Side_Deco_R_02_1.geometry} material={materials.mat_sidedeco_R01} >
            <meshPhysicalMaterial
              attach="material"
              roughness={0.8}
            >
              <primitive attach="map" object={texture} />
              <primitive attach="normalMap" object={normal} />
            </meshPhysicalMaterial>
          </mesh>
          <mesh name="Side_Deco_R_02_2" geometry={nodes.Side_Deco_R_02_2.geometry} material={materials.mat_walls_plinthe} />
        </group>
        <group name="Side_Deco_L_01">
          <mesh name="Side_Deco_L_01_1" geometry={nodes.Side_Deco_L_01_1.geometry} material={materials.mat_sidedeco_R01} >
            <meshPhysicalMaterial
              attach="material"
              roughness={0.8}
            >
              <primitive attach="map" object={texture} />
              <primitive attach="normalMap" object={normal} />
            </meshPhysicalMaterial>
          </mesh>
          <mesh name="Side_Deco_L_01_2" geometry={nodes.Side_Deco_L_01_2.geometry} material={materials.mat_walls_plinthe} />
        </group>
        <mesh name="Doors" geometry={nodes.Doors.geometry} material={materials.mat_door_body} position={[0.03, 0, 0]} />
        <mesh name="Doors_Handles_L" geometry={nodes.Doors_Handles_L.geometry} material={materials.mat_lamp_support} position={[0.09, -0.03, 0]} />
        <mesh name="Doors_Handles_R" geometry={nodes.Doors_Handles_R.geometry} material={materials.mat_doors_handles} position={[0.03, -0.03, 0]} />
        <mesh castShadow name="Windows_Support" geometry={nodes.Windows_Support.geometry} material={materials.mat_windows_support} position={[0, -0.32, 0]} />
        <mesh castShadow name="Windows" geometry={nodes.Windows.geometry} material={materials.mat_windows_support} position={[0, -0.32, 0]} />

      </group>
    </group>
  )
}

useGLTF.preload( '/gallery_wip-transformed.glb' )
