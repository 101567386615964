import { Environment, Lightformer, Sky, useHelper } from "@react-three/drei"
import { useControls } from "leva"
import { useMemo, useRef } from "react"
import * as THREE from 'three'


export default function Lights() {

    const mainLightRef = useRef()
    const lightProps = useControls( {
        color: 'white',
        intensity: 0.9,
        position: [0,100,-7],
        distance: 300,
        angle: 0.8,
        penumbra: 0.5,
        decay: 0.5
    } )
 
    return <>
        <ambientLight
            intensity={0.6}
        />
        {/* <directionalLight
            position={[29, 50, -60]}
            intensity={0.3}
            shadowCamBot={-30}
            shadowCamTop={30}
            shadowCamL={53}
            shadowCamR={-53}
        />
        <pointLight
            intensity={0.25}
            position={[0, 19, 13]}
        /> */}
        <Sky distance={45} sunPosition={[0, 10, 0]} inclination={10} azimuth={0.25} />
        <spotLight   ref={mainLightRef} castShadow {...lightProps} shadow-mapSize={[2048, 2048]} shadow-bias={-0.000001} />

    </>
}