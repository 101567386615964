import { create } from 'zustand'

export default create((set) =>
{
    return {
        /**
         * Help
         */
        phase: 'false',
        setHelpOpen: () => set((state) =>({ phase: 'true'})),
        setHelpClose: () => set((state) =>({ phase: 'false'}))
    }
})