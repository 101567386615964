import { create } from 'zustand'

export default create((set) =>
{
    return {
        /**
         * Listing
         */
        phase: 'false',
        setListingOpen: () => set((state) =>({ phase: 'true'})),
        setListingClose: () => set((state) =>({ phase: 'false'}))
    }
})