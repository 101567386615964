import {
  AdaptiveDpr,
  AdaptiveEvents,
  BakeShadows,
  ContactShadows,
  Html,
  Loader,
  PerformanceMonitor,
  useGLTF,
  useProgress,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useRef, useState } from "react";
import CameraControl from "./components/CameraControls/CameraControls";
import Lights from "./components/Lights/Lights";
import "./style.css";
import RaycastWhenCameraMoves from "./utils/RayCastWhenCameraMoves";
import PaintingsClick from "./components/PaintingClick/PaintingsClick";
import MenuPainting from "./interfaces/MenuPainting/MenuPainting";
import MenuBurger from "./interfaces/MenuBurger/MenuBurger";
import MenuCanvas from "./interfaces/MenuCanvas/MenuCanvas";
import CameraMovement from "./components/CameraMovement/CameraMovement";
import IsMobileDevice from "./utils/IsMobileDevice";
import MenuBurgerMobile from "./interfaces/MenuBurgerMobile/MenuBurgerMobile";
import WarningMessage from "./utils/WarningMessage";
import CameraMovementMobile from "./components/CameraMovementMobile/CameraMovementMobile";
import { LoadingScreen } from "./interfaces/LoadingScreen/LoadingScreen";
import { Perf } from "r3f-perf";
import Effects from "./components/Effects/Effects";
import LoadingModel from "./components/LoadingModel/LoadingModel";
import StartMenu from "./interfaces/StartMenu/StartMenu";
import StartMenuMobile from "./interfaces/StartMenuMobile/StartMenuMobile";
import MenuPaintingMobile from "./interfaces/MenuPaintingMobile/MenuPaintingMobile";
import { isMobile } from 'react-device-detect';
import ListingOeuvre from "./interfaces/ListingOeuvre/ListingOeuvre";
import HelpPannel from "./interfaces/Help/HelpPannel";
import HelpMobilePannel from "./interfaces/HelpMobile/HelpMobilePannel";
export default function App() {

  const cameraControlsRef = useRef();
  const { nodes, materials } = useGLTF( "/floor_exclusion.gltf" );

  const [start, setStart] = useState( false );
  const [dpr, setDpr] = useState( 1.5 );

  const [isHover, setIsHover] = useState( true );
  return (
    <>
      <Canvas shadows gl={{ logarithmicDepthBuffer: true }} dpr={dpr}>
        <PerformanceMonitor
          onIncline={() => setDpr( 2 )}
          onDecline={() => setDpr( 1 )}
        />
        {/* <Perf position={'top-left'} /> */}
        {/* <AdaptiveDpr pixelated /> */}
        <AdaptiveEvents />
        <Suspense fallback={null}>
          <RaycastWhenCameraMoves />
          <CameraControl ref={cameraControlsRef} />
          {isHover === true && isMobile === false ? (
            <CameraMovement cameraControlsRef={cameraControlsRef}>
              <mesh
                visible={false}
                geometry={nodes.Floor_Exclusion.geometry}
                position={[0, 0, -0.06]}
                scale={1.5}
              />
            </CameraMovement>
          ) : (
            <CameraMovementMobile cameraControlsRef={cameraControlsRef}>
              <mesh
                visible={false}
                geometry={nodes.Floor_Exclusion.geometry}
                position={[0, 0, -0.06]}
                scale={1.5}
              />
            </CameraMovementMobile>
          )}
          <PaintingsClick cameraControlsRef={cameraControlsRef} />
          <Lights />
          <LoadingModel />
          <BakeShadows />
        </Suspense>
      </Canvas>

      {isMobile ? (
        <StartMenuMobile started={start} onStarted={() => setStart( true )} />
      ) : (
        <StartMenu started={start} onStarted={() => setStart( true )} />
      )}
      {isMobile ? (
        <MenuBurgerMobile
          cameraControlsRef={cameraControlsRef}
          started={start}
        />
      ) : (
        <MenuBurger cameraControlsRef={cameraControlsRef} started={start} />
      )}
      {isMobile ? (
        <HelpMobilePannel cameraControlsRef={cameraControlsRef} />
      ) : (
        <HelpPannel cameraControlsRef={cameraControlsRef} />
      )}

      
      <MenuPainting cameraControlsRef={cameraControlsRef} />
      <ListingOeuvre cameraControlsRef={cameraControlsRef} />
      <MenuCanvas
        cameraControlsRef={cameraControlsRef}
        isHover={isHover}
        setIsHover={setIsHover}
      />
      <LoadingScreen />
    </>
  );
}
