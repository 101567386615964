import useHelp from "../../store/UseHelp";
import "../Help/HelpPannel.css"

export default function HelpPannel() {

    // Interface pour l'interface d'aide 

    const helpInterfaceisClose = useHelp( ( state ) => state.setHelpClose )

    const helpPanel = useHelp( ( state ) => state.phase );

    const handleHelpMenuClose = () => {
        helpInterfaceisClose()
    }
    return <>
        {helpPanel != 'false' &&  (
            <div className="help__container">
                <div className="help__wrapper">
                    <img src='./icon-menu-close.svg' className="help__closeButton" onClick={handleHelpMenuClose}></img>
                    <div className="help__header" >
                        <div>
                            <h1 className="help__title">Comment ?</h1>
                        </div>
                        <div className="help__content__2">
                            <span className="help__span"><b>cliquer et déplacer la souris</b> pour tourner la caméra</span>
                            <img className="help__img" src="./img1/help_icon_slide.png"></img>
                        </div>
                        <div className="help__content" >
                            <img className="help__img" src="./img1/help_icon_arrows.png"></img>
                            <span className="help__span">utilisez vos touches <b>haut et bas </b >pour avancer et reculer</span>
                        </div>
                        <div className="help__content__2">
                            <span className="help__span"><b>cliquer sur l'oeuvre</b> ou sur le sol pour vous rendre directement à cet endroit</span>
                            <img className="help__img" src="./img1/help_icon_click.png"></img>
                        </div>
                        <div className="help__content">
                            <img className="help__img" src="./img1/help_icon_burger.png"></img>
                            <span className="help__span">cliquez sur <b>l'icône menu</b> pour accéder à la visite guidée au catalogue, au plein écran et autre</span>
                        </div>
                        <div className="help__content">
                            <span className="help__span"><b>pour de meilleurs perfomances</b> nous vons recommandons les navigateurs suivants</span>
                            <img className="help__img" src="./img1/help_icon_browser.png"></img>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
}