import { create } from 'zustand'

export default create((set) =>
{
    return {
        /**
         * InsideInterface
         */
        phase: 'false',
        setPhaseInside: () => set((state) =>({ phase: 'true'})),
        setPhaseOutside: () => set((state) =>({ phase: 'false'}))
    }
})