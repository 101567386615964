import Cartouches from "../../models/cartouches/Cartouches";
import { Gallery } from "../../models/gallery/Gallery";
import { Lamp } from "../../models/lamp/lamp";
import Stands from "../../models/stands/Stands";

export default function LoadingModel() {
    return <>
        <Gallery />
        <Stands />
        <Cartouches />
        <Lamp />
    </>
}