import "../ListingOeuvre/ListingOeuvre.css";
import useListing from "../../store/UseListing";
import { useState } from "react";

export default function ListingOeuvre( { cameraControlsRef } ) {
  // Interface est visible ou pas ? //
  const interfaceIsClosed = useListing( ( state ) => state.setListingClose );

  const interfaces = useListing( ( state ) => state.phase );

  const handleMenuCloseButton = () => {
    console.log( interfaces )
    interfaceIsClosed();
  };

  // ------------------------------------------------------------------------ //
  // Position prédéfinis devant les tableau ainsi que leurs rotations         //
  // ----------------------------------------------------------------------- //

  var tabPos = [
    [-10.9, 2.0, -15.9], // Tableau 1
    [-3.4, 2, -15.9], // Tableau 2
    [4.1, 2, -15.9], // Tableau 3
    [11.5, 2, -15.9], // Tableau 4
    [12.3, 2, -8.3], // Tableau 5
    [9.3, 2, -8.6], // Tableau 20
    [6.3, 2, -8.6], // Tableau 19
    [-5.7, 2, -8.6], // Tableau 18
    [-8.6, 2, -8.6], // Tableau 17
    [-8.6, 2, -2.18], // Tableau 24
    [-5.6, 2, -2.19], // Tableau 23
    [6.3, 2, -2.18], // Tableau 22
    [9.3, 2, -2.18], // Tableau 21
    [12.3, 2, -2.3], // Tableau 6
    [9.3, 2, -1.16], // Tableau 28
    [6.3, 2, -1.16], // Tableau 27
    [-5.6, 2, -1.16], // Tableau 26
    [-8.6, 2, -1.16], // Tableau 25
    [-8.6, 2, 5.3], // Tableau 32
    [-5.6, 2, 5.3], // Tableau 31
    [6.3, 2, 5.3], // Tableau 30
    [9.3, 2, 5.3], // Tableau 29
    [12.3, 2, 2], // Tableau 7
    [12.3, 2, 6.5], // Tableau 8
    [9.3, 2, 6.3], // Tableau 36
    [6.3, 2, 6.3], // Tableau 35
    [-5.6, 2, 6.3], // Tableau 34
    [-8.6, 2, 6.3], // Tableau 33
    [-8.6, 2, 12.8], // Tableau 40
    [-5.6, 2, 12.8], // Tableau 39
    [6.3, 2, 12.8], // Tableau 38
    [9.3, 2, 12.8], // Tableau 37
    [12.3, 2, 13.5], // Tableau 9
    [0.3, 2, 7], // Tableau 10
    [-11.6, 2, 13.7], // Tableau 11
    [-11.6, 2, 9.2], // Tableau 12
    [-11.6, 2, 4.6], // Tableau 13
    [-11.6, 2, -0.52], // Tableau 14
    [-11.6, 2, -5.7], // Tableau 15
    [-11.6, 2, -10.8], // Tableau 16
  ];

  var sidePos = [
    [0, 1.57], // Tableau 1
    [0, 1.57], // Tableau 2
    [0, 1.57], // Tableau 3
    [0, 1.57], // Tableau 4
    [-1.57, 1.57], // Tableau 5
    [Math.PI, 1.57], // Tableau 20
    [Math.PI, 1.57], // Tableau 19
    [Math.PI, 1.57], // Tableau 18
    [Math.PI, 1.57], // Tableau 17
    [0, 1.57], // Tableau 24
    [0, 1.57], // Tableau 23
    [0, 1.57], // Tableau 22
    [0, 1.57], // Tableau 21
    [-1.57, 1.57], // Tableau 6
    [Math.PI, 1.57], // Tableau 28
    [Math.PI, 1.57], // Tableau 27
    [Math.PI, 1.57], // Tableau 26
    [Math.PI, 1.57], // Tableau 25
    [0, 1.57], // Tableau 32
    [0, 1.57], // Tableau 31
    [0, 1.57], // Tableau 30
    [0, 1.57], // Tableau 29
    [-1.57, 1.57], // Tableau 7
    [-1.57, 1.57], // Tableau 8
    [Math.PI, 1.57], // Tableau 36
    [Math.PI, 1.57], // Tableau 35
    [Math.PI, 1.57], // Tableau 34
    [Math.PI, 1.57], // Tableau 33
    [0, 1.57], // Tableau 40
    [0, 1.57], // Tableau 39
    [0, 1.57], // Tableau 38
    [0, 1.57], // Tableau 37
    [-1.57, 1.57], // Tableau 9
    [Math.PI, 1.57], // Tableau 10
    [Math.PI / 2, 1.57], // Tableau 11
    [Math.PI / 2, 1.57], // Tableau 12
    [Math.PI / 2, 1.57], // Tableau 13
    [Math.PI / 2, 1.57], // Tableau 14
    [Math.PI / 2, 1.57], // Tableau 15
    [Math.PI / 2, 1.57], // Tableau 16
  ];

  // state pour le switch entre les deux tableau

  const [activeArray, setActiveArray] = useState( true );

  const [activeImageList, setActiveImageList] = useState( true )

  return (
    <>
      {interfaces != 'false' && ( // Changement de "false" à false car interfaces est probablement un booléen
        <div className="listingOeuvre__wrapper">
          <div className="listingOeuvre__header">
            <div className="listingOeuvre__buttons">
              <img
                className="listingOeuvre__image"
                src={activeImageList ? './Icons/group_icon.svg' : './Icons/group_icon_OFF.svg'}
                onClick={() => {
                  setActiveArray( true )
                  setActiveImageList( true )
   
                }}
              >
              </img>

              <img
                src={activeImageList ? './Icons/list_icon_OFF.svg' : './Icons/list_icon.svg'}
                onClick={() => {
                  setActiveArray( false )
                  setActiveImageList( false )
                }}
              >
              </img>
 
              <h1 className="listingOeuvre__title">Listing des oeuvres</h1>
            </div>
            <img
              src="./icon-menu-close.svg"
              className="listingOeuvre__button"
              onClick={handleMenuCloseButton}
            >
            </img>
          </div>
          {activeArray === true ? ( // Changement de "true" à true car activeArray est probablement un booléen
            <div className="listingOeuvre__container">
              {tabPos.map( ( tabPos, index ) => (
                <div key={index} className="listingOeuvre__row">
                  <div className="listingOeuvre__item">
                    <h2
                      onClick={() => {
                        cameraControlsRef.current.moveTo(
                          tabPos[0],
                          tabPos[1],
                          tabPos[2],
                          true
                        );
                        cameraControlsRef.current.rotateTo(
                          sidePos[index][0],
                          sidePos[index][1],
                          true
                        );
                        interfaceIsClosed();
                      }}
                    >
                      {index + 1}
                    </h2>
                  </div>
                </div>
              ) )}
            </div>
          ) : (
            <div className="listingOeuvre__container--liste">
              {tabPos.map( ( tabPos, index ) => (
                <div key={index} className="listingOeuvre__row--liste">
                  <div className="listingOeuvre__item--liste">
                    <div>
                      <h2
                        onClick={() => {
                          cameraControlsRef.current.moveTo(
                            tabPos[0],
                            tabPos[1],
                            tabPos[2],
                            true
                          );
                          cameraControlsRef.current.rotateTo(
                            sidePos[index][0],
                            sidePos[index][1],
                            true
                          );
                          interfaceIsClosed();
                        }}
                      >
                        {index + 1}
                      </h2>
                    </div>
                    <div>
                      <h3>Daniel Nickersfield</h3>
                      <p>Lisa is a fu*cking capitalist !</p>
                      <span>2023</span><hr></hr><span>FRANCE</span><hr></hr><span>COLLAGE</span><hr></hr><span>45 X 45 CM</span><hr></hr><span>ESTIM 2.500.00€</span>
                    </div>
                  </div>
                </div>
              ) )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
