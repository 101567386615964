/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.0.9 gallery_wip.gltf --transform -k -K
*/

import React, { useRef } from 'react'
import { useGLTF, useTexture } from '@react-three/drei'
import * as THREE from 'three'
export default function Stands() {
  const { nodes, materials } = useGLTF( '/gallery_wip-transformed.glb' )

  const [texture, normal] = useTexture( [
    "Wall/Textures/White_Wall.jpg",
    "Wall/Textures/White_Wall_NORMAL.jpg",
  ] )

  const size = 20;

  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( size, size );

  normal.wrapS = THREE.RepeatWrapping;
  normal.wrapT = THREE.RepeatWrapping;
  normal.repeat.set( size, size );

  return <>
    <group dispose={null}
      onPointerDown={( e ) => e.stopPropagation()}
      onPointerMove={(e) => e.stopPropagation()} 
      onPointerOver={(e) =>{
        document.body.style.cursor = 'grab'
      } }
      onPointerOut={(e) =>{
        document.body.style.cursor = 'pointer'
      } }
    >
    <group name="Stand_01">
      <mesh name="Stand_01_1" geometry={nodes.Stand_01_1.geometry} receiveShadow>
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}
        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>
      <mesh name="Stand_01_2" geometry={nodes.Stand_01_2.geometry} material={materials.mat_walls_plinthe} receiveShadow >
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}

        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>

    </group>
    <group name="Stand_02" position={[7.85, 0.97, -5.43]}>
      <mesh name="Stand_02_1" geometry={nodes.Stand_02_1.geometry} material={materials.mat_stand_02} receiveShadow >
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}
        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>
      <mesh name="Stand_02_2" geometry={nodes.Stand_02_2.geometry} material={materials.mat_walls_plinthe} >
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}
        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>
    </group>
    <group name="Stand_03" position={[15, 0, 7.5]}>
      <mesh name="Stand_03_1" geometry={nodes.Stand_03_1.geometry} material={materials.mat_stand_03} >
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}
        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>
      <mesh name="Stand_03_2" geometry={nodes.Stand_03_2.geometry} material={materials.mat_walls_plinthe} />
    </group>
    <group name="Stand_04" position={[0, 0, 7.5]}>
      <mesh name="Stand_04_1" geometry={nodes.Stand_04_1.geometry} material={materials.mat_stand_04} >
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}
        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>
      <mesh name="Stand_04_2" geometry={nodes.Stand_04_2.geometry} material={materials.mat_walls_plinthe} />
    </group>
    <group name="Stand_05" position={[0, 0, 15]}>
      <mesh name="Stand_05_1" geometry={nodes.Stand_05_1.geometry} material={materials.mat_stand_04} >
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}
        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>
      <mesh name="Stand_05_2" geometry={nodes.Stand_05_2.geometry} material={materials.mat_walls_plinthe} />
    </group>
    <group name="Stand_06" position={[15, 0, 15]}>
      <mesh name="Stand_06_1" geometry={nodes.Stand_06_1.geometry} material={materials.mat_stand_04} >
        <meshPhysicalMaterial
          attach="material"
          clearcoat={1}
          roughness={0.5}
        >
          <primitive attach="map" object={texture} />
          <primitive attach="normalMap" object={normal} />
        </meshPhysicalMaterial>
      </mesh>
      <mesh name="Stand_06_2" geometry={nodes.Stand_06_2.geometry} material={materials.mat_walls_plinthe} />


    </group>
  </group>
  </>
}