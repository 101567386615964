import { useProgress } from "@react-three/drei";
import { useEffect, useState } from "react";
import { Dna, Triangle } from "react-loader-spinner";
import "../LoadingScreen/LoadingScreen.css"

export const LoadingScreen = ( { isProgress } ) => {
  const { active, progress, errors, item, loaded, total } = useProgress()

  const [isTotal, setIsTotal] = useState( false )

  useEffect( () => {
    if ( total === 21 && progress === 100 ) {
      setTimeout( () => {
        setIsTotal( true )
      }, 3000 )

    }
  }, [total, progress] )

  return (
      <div className={`loadingScreen ${isTotal ? "loadingScreen--started" : ""}`}>
        <Triangle
          height="200px"
          width="200px"
          color="black"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    )
};