import { create } from 'zustand'

export default create((set) =>
{
    return {
        
        index: 0,
        setIndex:(input)=>set((state)=>({index:input})),
        setIndexForward: () => set((state) =>({ index: state.index + 1})),
        setIndexBackward: () => set((state) =>({ index: state.index - 1})),
    }
})