import { useRef, useState } from "react";
import "../MenuBurger/MenuBurger.css"
import { useIsLandscape } from "../../utils/is_landscape";
import { useEffect } from "react";
import UsePaintingIndex from "../../store/UsePaintingIndex";
import * as THREE from 'three'
import useBearStore from "../../store/useBearStore";
import Tooltip from "../ToolTip/ToolTip";
import useListing from "../../store/UseListing"
import useHelp from "../../store/UseHelp";

export default function MenuBurger( { cameraControlsRef, started } ) {
    const [isMenuOpen, setIsMenuOpen] = useState( false );
    const [style, setStyle] = useState()
    const [isSafari, setIsSafari] = useState()

    // Jouer le son 
    const [isPlaying, setIsPlaying] = useState( false );
    const [isShown, setIsShown] = useState( false );
    const audioRef = useRef( null );

    const handleButtonClick = () => {
        setIsShown( !isShown );
        if ( isPlaying ) {
            console.log( audioRef.current )
            audioRef.current.pause();
            setIsPlaying( false );
        } else {
            audioRef.current.play();
            setIsPlaying( true );
        }

    };

    useEffect( () => {
        if ( audioRef.current ) {
            const audioElement = audioRef.current;

            const playPromise = audioElement.play();
            if ( playPromise !== undefined ) {
                playPromise.then( () => {
                    audioElement.pause();
                } );
            }
        }
    }, [] );


    // Interface pour le listing des oeuvres //
    const listingInterfaceisOpen = useListing((state) => state.setListingOpen )
    const listingInterfaceisClose =  useListing((state) => state.setListingClose )

    const handleMenuListingClick = () => {
        listingInterfaceisOpen()
        setIsMenuOpen(false)
        helpInterfaceisClose()
    }

    // Interface pour l'interface d'aide 

    const helpInterfaceisOpen = useHelp((state) => state.setHelpOpen)
    const helpInterfaceisClose = useHelp((state) => state.setHelpClose)

    const handleHelpMenuClick = () =>{
        helpInterfaceisOpen()
        listingInterfaceisClose()
        setIsMenuOpen(false)
    }
    var screen = useIsLandscape();

    // Fermeture/Ouverture du Menu burger
    function handleMenuOpen() {
        setIsMenuOpen( true );
    }

    function handleMenuClose() {
        setIsMenuOpen( false );
    }

    // Fonction pour enter/quitter le fullscreen
    function enterFullScreen() {
        if ( !document.fullscreenElement ) {
            document.documentElement.requestFullscreen();
            setIsMenuOpen( false )
        } else {
            if ( document.exitFullscreen ) {
                document.exitFullscreen();
                setIsMenuOpen( false )
            }
        };
    }
    useEffect( () => {
        if ( screen ) {
            setStyle( { position: 'absolute', top: '1rem', right: '1rem', borderRadius: '50%', backgroundColor: 'white' } )
        }
        else {
            setStyle( { visibility: 'hidden' } )
        }
    }, [screen] )

    useEffect( () => {
        // Vérifie si le navigateur est Safari
        if ( navigator.userAgent.indexOf( 'Safari' ) != -1 && navigator.userAgent.indexOf( 'Chrome' ) == -1 ) {
            setIsSafari( true )
        } else {
            setIsSafari( false )
        }
    }, [navigator.userAgent] )


    

    // Bouton d'interface 

    const bears = useBearStore( ( state ) => state.bears )

    const remove = useBearStore( ( state ) => state.remove )

    const inc = useBearStore( ( state ) => state.inc )

    const setIndex = UsePaintingIndex( ( state ) => state.setIndex )



    return (
        <>
            {screen && isMenuOpen ? (
                <div className="burgerMenu--deskstop" >
                    <div>
                        <img className="burgerMenu__icones" onClick={handleMenuClose} src="/icon-menu-close.svg"></img>
                    </div>

                    <div style={isSafari ? { display: 'none' } : { display: 'block' }}>
                        {document.fullscreenElement ?
                            <Tooltip tooltip={<img style={{ width: '9rem', marginLeft: '-90px' }} src="/Icons/infobulle-fullscreen-off.svg"></img>}>
                                <img className="burgerMenu__icones" onClick={enterFullScreen} src="/Icons/icon-fullscreen-on.svg"></img>
                            </Tooltip>
                            :
                            <Tooltip tooltip={<img style={{ width: '7rem', marginLeft: '-58px' }} src="/Icons/infobulle-fullscreen.svg"></img>}>
                                <img className="burgerMenu__icones" onClick={enterFullScreen} src="/Icons/icon-fullscreen-off.svg"></img>
                            </Tooltip>
                        }
                    </div>
                    <Tooltip tooltip={<img style={{ width: '10rem', marginLeft: '-106px' }} src="/Icons/infobulle-reset.svg"></img>}>
                        <img onClick={() => {
                            cameraControlsRef.current.moveTo( 0, 2, -10, true )
                            cameraControlsRef.current.rotateTo( Math.PI, Math.PI / 2, true )
                            cameraControlsRef.current.camera.near = 0.1
                            remove()
                        }}
                            className="burgerMenu__icones" src="/Icons/icon-reset.svg"></img>
                    </Tooltip>

                    <Tooltip tooltip={<img src="/Icons/infobulle-help.svg" style={{ width: '4rem', marginLeft: '-10px' }} ></img>}>
                        <img className="burgerMenu__icones" src="/Icons/icon-help.svg" onClick={handleHelpMenuClick}></img>
                    </Tooltip>


                    <Tooltip tooltip={<img style={{ width: '8rem', marginLeft: '-74px' }} src="/Icons/infobulle-start-visit.svg"></img>}>
                        <img
                            className="burgerMenu__icones"
                            onClick={() => {
                                const normalizedAzimuthAngle = THREE.MathUtils.euclideanModulo( cameraControlsRef.current.azimuthAngle, 360 * THREE.MathUtils.DEG2RAD );
                                var x;
                                if ( normalizedAzimuthAngle > ( 180 * THREE.MathUtils.DEG2RAD ) ) {
                                    x = 360 * THREE.MathUtils.DEG2RAD
                                }
                                else {
                                    x = 0
                                }
                                setIndex( 0 )
                                cameraControlsRef.current.normalizeRotations()
                                cameraControlsRef.current.moveTo( -10.9, 2.02, -15.91, true )
                                cameraControlsRef.current.rotateTo( x, Math.PI / 2, true ).then( inc() )
                            }}
                            src="Icons/icon-start-visit.svg"></img>
                    </Tooltip>

                    <Tooltip tooltip={<img style={{ width: '11rem', marginLeft: '-122px' }} src="/Icons/infobulle-list.svg"></img>}>
                        <img className="burgerMenu__icones" src="/Icons/icon-list.svg" onClick={handleMenuListingClick}></img>
                    </Tooltip>
                    <div>
                        <audio ref={audioRef} loop>
                            <source src="/sing.mp3" type="audio/mp3" />
                            Votre navigateur ne prend pas en charge l'élément audio.
                        </audio>
                    </div>
                    <Tooltip tooltip={<img style={{ width: '4rem', marginLeft: '-10px' }} src="/Icons/infobulle-info.svg"></img>}>
                        <img className="burgerMenu__icones" src="/Icons/icon-info.svg"></img>
                    </Tooltip>

                </div>
            )
                :
                (

                    <img className="hamburger" onClick={handleMenuOpen} style={style} src="/Icons/icon-menu-open.svg">
                    </img>

                )}

            {/* {isTourOpen && <div className="GalleryTourMenu">Contenu de la div</div>} */}

        </>
    );
}