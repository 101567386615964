import { useRef, useState } from "react";
import "../MenuBurgerMobile/MenuBurgerMobile.css"
import { useIsLandscape } from "../../utils/is_landscape";
import { useEffect } from "react";
import UsePaintingIndex from "../../store/UsePaintingIndex";
import * as THREE from 'three'
import useBearStore from "../../store/useBearStore";
import TooltipMobile from "../ToolTip/ToolTipMobile";
import useListing from "../../store/UseListing"
import useHelp from "../../store/UseHelp";

export default function MenuBurger( { cameraControlsRef } ) {
    const [isMenuOpen, setIsMenuOpen] = useState( false );


    // Function pour detecter si c'est un Iphone ou pas 
    var isIphone
    isIphone = iOS()

    function iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes( navigator.platform )
            // iPad on iOS 13 detection
            || ( navigator.userAgent.includes( "Mac" ) && "ontouchend" in document )
    }

    // Fermeture/Ouverture du Menu burger
    function handleMenuOpen() {
        console.log( 'open' )
        setIsMenuOpen( true );
    }

    function handleMenuClose() {
        setIsMenuOpen( false );
    }

    // Fonction pour enter/quitter le fullscreen
    function enterFullScreen() {
        if ( !document.fullscreenElement ) {
            document.documentElement.requestFullscreen();
            setIsMenuOpen( false )
        } else {
            if ( document.exitFullscreen ) {
                document.exitFullscreen();
                setIsMenuOpen( false )
            }
        };
    }

    // Interface pour le listing des oeuvres //
    const listingInterfaceisOpen = useListing( ( state ) => state.setListingOpen )
    const listingInterfaceisClose =  useListing((state) => state.setListingClose)


    const handleMenuListingClick = () => {
        listingInterfaceisOpen()
        setIsMenuOpen( false )
    }

    // Interface pour l'interface d'aide 

    const helpInterfaceisOpen = useHelp( ( state ) => state.setHelpOpen )
    const helpInterfaceisClose = useHelp( ( state ) => state.setHelpClose )

    const handleHelpMenuClick = () => {
        helpInterfaceisOpen()
        listingInterfaceisClose()
        setIsMenuOpen( false )
    }
    // Bouton d'interface 

    const setIndex = UsePaintingIndex( ( state ) => state.setIndex )

    const inc = useBearStore( ( state ) => state.inc )

    const remove = useBearStore( ( state ) => state.remove )

    return (
        <>
            {isMenuOpen ? (
                <div className="burgerMenu--mobile">
                    <div className="burgerMenu__iconesContainer--mobile">
                        <TooltipMobile tooltip={<img style={{ width: '2rem', marginLeft: '0px' }} src="/img/logo-pictogramme.png"></img>}>
                            <img className="burgerMenu__icones" onClick={handleMenuClose} src="/icon-menu-close.svg"></img>
                        </TooltipMobile>

                        <div style={isIphone ? { display: 'none' } : { display: 'block' }}>
                            {document.fullscreenElement ?
                                <TooltipMobile tooltip={<img style={{ width: '10rem', marginLeft: '-106px' }} src="/Icons/infobulle-fullscreen-off.svg"></img>}>
                                    <img className="burgerMenu__icones" onClick={enterFullScreen} src="/Icons/icon-fullscreen-on.svg"></img>
                                </TooltipMobile>
                                :
                                <TooltipMobile tooltip={<img style={{ width: '7rem', marginLeft: '-58px' }} src="/Icons/infobulle-fullscreen.svg"></img>}>
                                    <img className="burgerMenu__icones" onClick={enterFullScreen} src="/Icons/icon-fullscreen-off.svg"></img>
                                </TooltipMobile>
                            }
                        </div>
                        <TooltipMobile tooltip={<img style={{ width: '10rem', marginLeft: '-106px' }} src="/Icons/infobulle-reset.svg"></img>}>
                            <img onClick={() => {
                                cameraControlsRef.current.moveTo( 0, 2, -10, true )
                                cameraControlsRef.current.rotateTo( Math.PI, Math.PI / 2, true )
                                cameraControlsRef.current.camera.near = 0.1
                                remove()
                            }}
                                className="burgerMenu__icones" src="/Icons/icon-reset.svg"></img>
                        </TooltipMobile>

                        <TooltipMobile tooltip={<img src="/Icons/infobulle-help.svg" style={{ width: '4rem', marginLeft: '-10px' }} ></img>}>
                            <img className="burgerMenu__icones" src="/Icons/icon-help.svg" onClick={handleHelpMenuClick}></img>
                        </TooltipMobile>


                        <TooltipMobile tooltip={<img style={{ width: '8rem', marginLeft: '-74px' }} src="/Icons/infobulle-start-visit.svg"></img>}>
                            <img
                                className="burgerMenu__icones"
                                onClick={() => {
                                    const normalizedAzimuthAngle = THREE.MathUtils.euclideanModulo( cameraControlsRef.current.azimuthAngle, 360 * THREE.MathUtils.DEG2RAD );
                                    var x;
                                    if ( normalizedAzimuthAngle > ( 180 * THREE.MathUtils.DEG2RAD ) ) {
                                        x = 360 * THREE.MathUtils.DEG2RAD
                                    }
                                    else {
                                        x = 0
                                    }
                                    setIndex( 0 )
                                    cameraControlsRef.current.normalizeRotations()
                                    cameraControlsRef.current.moveTo( -10.9, 2.02, -15.91, true )
                                    cameraControlsRef.current.rotateTo( x, Math.PI / 2, true ).then( inc() )
                                }}
                                src="Icons/icon-start-visit.svg"></img>
                        </TooltipMobile>

                        <TooltipMobile tooltip={<img style={{ width: '11rem', marginLeft: '-122px' }} src="/Icons/infobulle-list.svg"></img>}>
                            <img className="burgerMenu__icones" src="/Icons/icon-list.svg" onClick={handleMenuListingClick}></img>
                        </TooltipMobile>

                        <TooltipMobile tooltip={<img style={{ display: 'none', width: '4rem', marginLeft: '-10px' }} src="/Icons/infobulle-info.svg"></img>}>
                            <img className="burgerMenu__icones" src="/Icons/icon-info.svg"></img>
                        </TooltipMobile>
                    </div>
                </div>
            )
                :
                (

                    <img className="hamburger" onClick={handleMenuOpen} src="/Icons/icon-menu-open.svg">
                    </img>

                )}

            {/* {isTourOpen && <div className="GalleryTourMenu">Contenu de la div</div>} */}

        </>
    );
}