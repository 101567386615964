import useHelp from "../../store/UseHelp";
import "../HelpMobile/HelpMobilePannel.css"

export default function HelpMobilePannel() {

    // Interface pour l'interface d'aide 

    const helpInterfaceisClose = useHelp( ( state ) => state.setHelpClose )

    const helpPanel = useHelp( ( state ) => state.phase );

    const handleHelpMenuClose = () => {

        helpInterfaceisClose()
    }

    console.log( helpPanel )
    return <>
        {helpPanel != 'false' && (
            <div className="help--mobile__container">
                <img src='./icon-menu-close.svg' className="help--mobile__closeButton" onClick={handleHelpMenuClose}></img>
                <div>
                    <img className="help--mobile__image" src="./img1/help_pannel_mobile.png"></img>
                </div>
            </div>
        )}
    </>
}