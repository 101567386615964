import Paintings from '../../models/paintings/Paintings'
import UseInterface from '../../store/UseInterface';
import UsePaintingIndex from '../../store/UsePaintingIndex';
import * as THREE from 'three'
import IsMobileDevice from '../../utils/IsMobileDevice';
import useBearStore from '../../store/useBearStore';
import { useEffect, useState } from 'react';

export default function PaintingsClick( { cameraControlsRef } ) {

    const [cameraIsDragging, setCameraIsDragging] = useState()
    const interfaces = UseInterface( ( state ) => state.phase )
    const interfaceIsOpen = UseInterface( ( state ) => state.setPhaseInside )

    // Bouton qui incrémente les index //

    const setIndex = UsePaintingIndex( ( state ) => state.setIndex )

    // Setter pour quand le mouvement de camera est finit
    const inc = useBearStore( ( state ) => state.inc )

    const remove = useBearStore( ( state ) => state.remove )


    useEffect( () => {
        cameraControlsRef.current.addEventListener( 'control', function () {
            setCameraIsDragging( false )
        } )
        cameraControlsRef.current.addEventListener( 'controlend', function () {
            setCameraIsDragging( true )
        } )
    }, [cameraControlsRef] )


    // Récupere l'evenement de click sur une peinture, pour ensuite zoomer desus
    const handleClick = ( event ) => {
        cameraControlsRef.current.normalizeRotations()
        const boundingBox = new THREE.Box3();
        // get bounding box of object - this will be used to setup controls and camera

        boundingBox.setFromObject( event.object );

        var middle = new THREE.Vector3();

        middle.x = ( boundingBox.max.x + boundingBox.min.x ) / 2;
        middle.y = ( boundingBox.max.y + boundingBox.min.y ) / 2;
        middle.z = ( boundingBox.max.z + boundingBox.min.z ) / 2;

        var side = event.object.side

        if ( IsMobileDevice() === false ) {

            if ( side === 'front' ) {
                setIndex( event.object.number )
                const normalizedAzimuthAngle = THREE.MathUtils.euclideanModulo( cameraControlsRef.current.azimuthAngle, 360 * THREE.MathUtils.DEG2RAD );
                var x;
                if ( normalizedAzimuthAngle > ( 180 * THREE.MathUtils.DEG2RAD ) ) {
                    x = 360 * THREE.MathUtils.DEG2RAD
                }
                else {
                    x = 0
                }
                cameraControlsRef.current.moveTo( middle.x, middle.y, middle.z + 3, true )
                cameraControlsRef.current.rotateTo( x, Math.PI / 2, true )
                inc()


                if ( event.distance > 2.999 && event.distance < 3.1 ) {
                    remove()
                    setIndex( event.object.number )
                    interfaceIsOpen()
                }
            }
            else if ( side === 'back' ) {
                setIndex( event.object.number )

                cameraControlsRef.current.moveTo( middle.x, middle.y, middle.z - 3, true )
                cameraControlsRef.current.rotateTo( Math.PI, ( Math.PI / 2 ), true )
                inc()

                if ( event.distance > 2.999 && event.distance < 3.1 ) {
                    remove()
                    setIndex( event.object.number )
                    interfaceIsOpen()

                }
            }
            else if ( side === 'left' ) {
                setIndex( event.object.number )


                cameraControlsRef.current.moveTo( middle.x - 3, middle.y, middle.z, true )
                cameraControlsRef.current.rotateTo( 4.7211, ( Math.PI / 2 ), true )
                inc()

                if ( event.distance > 2.999 && event.distance < 3.1 ) {
                    remove()
                    setIndex( event.object.number )
                    interfaceIsOpen()

                }
            }
            else if ( side === 'right' ) {
                setIndex( event.object.number )


                cameraControlsRef.current.moveTo( middle.x + 3, middle.y, middle.z, true )
                cameraControlsRef.current.rotateTo( Math.PI / 2, ( Math.PI / 2 ), true )
                inc()

                if ( event.distance > 2.999 && event.distance < 3.1 ) {
                    remove()
                    setIndex( event.object.number )
                    interfaceIsOpen()

                }

            }
            else if ( side === 'bigone' ) {

                setIndex( event.object.number )
                cameraControlsRef.current.moveTo( middle.x, middle.y - 1.2, middle.z - 10, true )
                cameraControlsRef.current.rotateTo( Math.PI, ( Math.PI / 2 ), true )
            }
        }
        if ( IsMobileDevice() === true ) {
          
            if ( side === 'front' ) {
                setIndex( event.object.number )
                if ( event.distance >= 2.5 && event.distance < 2.60 ) {
                    interfaceIsOpen()
                }

                const normalizedAzimuthAngle = THREE.MathUtils.euclideanModulo( cameraControlsRef.current.azimuthAngle, 360 * THREE.MathUtils.DEG2RAD );
                var x;
                if ( normalizedAzimuthAngle > ( 180 * THREE.MathUtils.DEG2RAD ) ) {
                    x = 360 * THREE.MathUtils.DEG2RAD
                }
                else {
                    x = 0
                }

                cameraControlsRef.current.moveTo( middle.x, middle.y, middle.z + 2.5, true )
                cameraControlsRef.current.rotateTo( x, ( Math.PI / 2 ), true )
                inc()
            }
            else if ( side === 'back' ) {
                setIndex( event.object.number )
                if ( event.distance > 2.5 && event.distance < 2.60 ) {

                    interfaceIsOpen()
                }

                cameraControlsRef.current.moveTo( middle.x, middle.y, middle.z - 2.5, true )
                cameraControlsRef.current.rotateTo( Math.PI, ( Math.PI / 2 ), true )
                inc()
            }
            else if ( side === 'left' ) {
                setIndex( event.object.number )
                if ( event.distance > 2.5 && event.distance < 2.60 ) {

                    interfaceIsOpen()
                }
                cameraControlsRef.current.moveTo( middle.x - 2.5, middle.y, middle.z, true )
                cameraControlsRef.current.rotateTo( -Math.PI / 2, ( Math.PI / 2 ), true )
                inc()
            }
            else if ( side === 'right' ) {
                setIndex( event.object.number )
                if ( event.distance > 2.5 && event.distance < 2.60 ) {

                    interfaceIsOpen()
                }
                cameraControlsRef.current.moveTo( middle.x + 2.60, middle.y, middle.z, true )
                cameraControlsRef.current.rotateTo( Math.PI / 2, ( Math.PI / 2 ), true )
                inc()
            }
            else if ( side === 'bigone' ) {
                setIndex( event.object.number )
                cameraControlsRef.current.moveTo( middle.x, middle.y - 1.2, middle.z - 9, true )
                cameraControlsRef.current.rotateTo( Math.PI, ( Math.PI / 2 ), true )
            }
        }
    };

    return <>
        <group
            onClick={( event ) => {
                if ( cameraIsDragging === true ) {
                    handleClick( event )
                }
                event.stopPropagation()
            }}
            onPointerOver={() => {
                document.body.style.cursor = 'pointer'
            }}
            onPointerOut={() =>{
                document.body.style.cursor = 'grab'
            }}
        >
            <Paintings />
        </group>
    </>
}