
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from 'three'
import useBearStore from "../../store/useBearStore";
import TWEEN from '@tweenjs/tween.js';

export default function CameraMovementMobile( { children, cameraControlsRef }) {

    const { nodes, materials } = useGLTF( "/floor_exclusion.gltf" );
    const [animationStart, setAnimationStart] = useState( true )

    // create objects and add to scene

    const circleRef = useRef();
    const ringRef = useRef()

    const ref = useRef()

    // useEffect(() =>{
    //     circleRef.current.visible =false;
    //     ringRef.current.visible =false;
    // })

    const [pointer] = useState( () => new THREE.Vector3() )
    const [normal] = useState( () => new THREE.Vector3() )

    const remove = useBearStore( ( state ) => state.remove )

    function moveCamera( position, duration ) {
    
        const currentPosition = cameraControlsRef.current._target.clone();

        // create a new tween
        const tween = new TWEEN.Tween( { t: 0 } )
            .to( { t: 1 }, duration * 1000 )
            .onUpdate( ( obj ) => {
                const newPosition = currentPosition.clone().lerp( position, obj.t );
                cameraControlsRef.current.moveTo( newPosition.x, 2, newPosition.z );
            } );

        // create an animation to enlarge and fade the circle on start
        const circleTweens = [
            new TWEEN.Tween( { scale: 1, opacity: 0.7 } )
                .to( { scale: 1.7, opacity: 0 }, 500 )
                .easing( TWEEN.Easing.Quadratic.Out )
                .onUpdate( ( { scale, opacity } ) => {
                    circleRef.current.scale.set( scale, scale, scale );
                    circleRef.current.material.opacity = opacity;
                    ringRef.current.material.opacity = opacity;
                } ),
        ];

        // chain the circle animation with the camera movement
        tween.onStart( () => {
            circleTweens.forEach( ( t ) => t.start() );
            setAnimationStart( false )
        } );

        tween.onComplete( () => {
            // circleTweens.forEach( ( t ) => t.stop() );
            // circleRef.current.scale.set( 1, 1, 1 );
            // circleRef.current.material.opacity = 0.8;
            // ringRef.current.material.opacity = 0.5
            setAnimationStart( true )
        } );

        tween.start();
    }

    useFrame( () => {
        TWEEN.update();
    } );

    const [isVisible, setIsVisible] = useState(false)
    return <>
        <mesh
            visible={false}
            geometry={nodes.Floor_Exclusion.geometry}
            material={nodes.Floor_Exclusion.material}
            position={[0, 0, -0.06]}
            scale={1.5}
            onClick={( event ) => {
                setIsVisible(true)
                event.stopPropagation()
                if ( animationStart === true ) {
                    moveCamera( event.point, 1 );
                
                    ref.current.position.copy( event.point )
              
                    // Comment the next line if face normal isn't important
                    remove()
                }
            }}
        />
        <group
        >
            {children}
        </group>
        <group ref={ref} visible={isVisible}>
            <mesh rotation-x={Math.PI / 2} ref={circleRef} position-y={0.0001}>
                <ringGeometry args={[0.26, 0.3, 32]} />
                <meshBasicMaterial
                    transparent
                    opacity={0.8}
                    color="white"
                    toneMapped={false}
                    side={THREE.DoubleSide}
                    polygonOffset
                    polygonOffsetFactor={-1}
                />
            </mesh>
            <mesh rotation-x={Math.PI / 2} position-y={0.0001} ref={ringRef}>
                <circleGeometry args={[0.26, 32]} />
                <meshBasicMaterial
                    transparent
                    opacity={0.5}
                    toneMapped={false}
                    side={THREE.DoubleSide}
                    depthTest={true}
                />
            </mesh>
        </group>
    </>
}