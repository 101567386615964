import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useMemo } from 'react'
import * as THREE from 'three'
import { TextureLoader } from 'three'
import { useLoader } from '@react-three/fiber'

export function Lamp( props ) {
    const { nodes, materials } = useGLTF( '/gallery_wip-transformed.glb' )


    return <>
        <mesh name="Ceiling_Lamps_Supports" geometry={nodes.Ceiling_Lamps_Supports.geometry} material={materials.mat_lamp_support} position={[-0.39, 0, 0]} />
        <group name="Long_Lamp_Bodies">
            <mesh name="Long_Lamp_Bodies_1" geometry={nodes.Long_Lamp_Bodies_1.geometry} material={materials.mat_long_lamp_body} >

            </mesh>
            <mesh name="Long_Lamp_Bodies_2" geometry={nodes.Long_Lamp_Bodies_2.geometry} material={materials.mat_lamp_small_EM} >
            <meshBasicMaterial color={[1, 1, 1,1]} toneMapped={false} />
            </mesh>
        </group>
        <group name="Small_Lamp_Bodies_R" position={[-0.39, 0, 0]}>
            <mesh name="Small_Lamp_Bodies_R_1" geometry={nodes.Small_Lamp_Bodies_R_1.geometry} material={materials.mat_windows_support} >

            </mesh>
            <mesh name="Small_Lamp_Bodies_R_2" geometry={nodes.Small_Lamp_Bodies_R_2.geometry} material={materials.mat_lamp_small_EM} >
            <meshBasicMaterial color={[1, 1, 1,1]} toneMapped={false} />
            </mesh>
            <mesh name="Small_Lamp_Bodies_R_3" geometry={nodes.Small_Lamp_Bodies_R_3.geometry} material={materials.mat_lamp_support} >

            </mesh>
            <mesh name="Small_Lamp_Bodies_R_4" geometry={nodes.Small_Lamp_Bodies_R_4.geometry} material={materials.mat_lamp_small_EM} >
            <meshBasicMaterial color={[1, 1, 1,1]} toneMapped={false} />
            </mesh>
        </group>
        <group name="Small_Lamp_Bodies_L" position={[-0.39, 0, 0]}>
            <mesh name="Small_Lamp_Bodies_L_1" geometry={nodes.Small_Lamp_Bodies_L_1.geometry} material={materials.mat_windows_support} >

            </mesh>
            <mesh name="Small_Lamp_Bodies_L_2" geometry={nodes.Small_Lamp_Bodies_L_2.geometry} material={materials.mat_lamp_small_EM} >
                <meshBasicMaterial color={[1, 1, 1,1]} toneMapped={false} />
            </mesh>
        </group>
    </>
}

