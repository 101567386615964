import "../MenuPainting/MenuPainting.css";
import UsePaintingIndex from "../../store/UsePaintingIndex";
import UseInterface from "../../store/UseInterface";
import useBearStore from "../../store/useBearStore";
import InnerImageZoom from "react-inner-image-zoom";
import IsMobileDevice from "../../utils/IsMobileDevice";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import useListing from "../../store/UseListing"

export default function MenuPainting( { cameraControlsRef } ) {
  // event to move between div with arrow keys
  var a = "Daniel Nickersfield";
  var b = "La nuit étoilée";
  var c = "La belle madame";

  const h1Elements = [a, b, c];

  // Mobile

  const mobile = IsMobileDevice();

  //-------------------------------------------------------------------------//
  // Zustand                                                                //
  // -----------------------------------------------------------------------//

  // Interface est visible ou pas ? //
  const interfaceIsClosed = UseInterface( ( state ) => state.setPhaseOutside );

  const interfaces = UseInterface( ( state ) => state.phase );

  // Bouton qui incrémente les index //
  const index = UsePaintingIndex( ( state ) => state.index );

  const setIndex = UsePaintingIndex( ( state ) => state.setIndex );

  const increamentIndex = UsePaintingIndex( ( state ) => state.setIndexForward );

  const decrementIndex = UsePaintingIndex( ( state ) => state.setIndexBackward );

  // Bouton d'interface

  const bears = useBearStore( ( state ) => state.bears );

  const inc = useBearStore( ( state ) => state.inc );

  // ------------------------------------------------------------------------ //
  // Position prédéfinis devant les tableau ainsi que leurs rotations         //
  // ----------------------------------------------------------------------- //

  var tabPos = [
    [-10.9, 2.0, -15.9], // Tableau 1
    [-3.4, 2, -15.9], // Tableau 2
    [4.1, 2, -15.9], // Tableau 3
    [11.5, 2, -15.9], // Tableau 4
    [12.3, 2, -8.3], // Tableau 5
    [9.3, 2, -8.6], // Tableau 20
    [6.3, 2, -8.6], // Tableau 19
    [-5.7, 2, -8.6], // Tableau 18
    [-8.6, 2, -8.6], // Tableau 17
    [-8.6, 2, -2.18], // Tableau 24
    [-5.6, 2, -2.19], // Tableau 23
    [6.3, 2, -2.18], // Tableau 22
    [9.3, 2, -2.18], // Tableau 21
    [12.3, 2, -2.3], // Tableau 6
    [9.3, 2, -1.16], // Tableau 28
    [6.3, 2, -1.16], // Tableau 27
    [-5.6, 2, -1.16], // Tableau 26
    [-8.6, 2, -1.16], // Tableau 25
    [-8.6, 2, 5.3], // Tableau 32
    [-5.6, 2, 5.3], // Tableau 31
    [6.3, 2, 5.3], // Tableau 30
    [9.3, 2, 5.3], // Tableau 29
    [12.3, 2, 2], // Tableau 7
    [12.3, 2, 6.5], // Tableau 8
    [9.3, 2, 6.3], // Tableau 36
    [6.3, 2, 6.3], // Tableau 35
    [-5.6, 2, 6.3], // Tableau 34
    [-8.6, 2, 6.3], // Tableau 33
    [-8.6, 2, 12.8], // Tableau 40
    [-5.6, 2, 12.8], // Tableau 39
    [6.3, 2, 12.8], // Tableau 38
    [9.3, 2, 12.8], // Tableau 37
    [12.3, 2, 13.5], // Tableau 9
    [0.3, 2, 7], // Tableau 10
    [-11.6, 2, 13.7], // Tableau 11
    [-11.6, 2, 9.2], // Tableau 12
    [-11.6, 2, 4.6], // Tableau 13
    [-11.6, 2, -0.52], // Tableau 14
    [-11.6, 2, -5.7], // Tableau 15
    [-11.6, 2, -10.8], // Tableau 16
  ];

  var sidePos = [
    [0, 1.57], // Tableau 1
    [0, 1.57], // Tableau 2
    [0, 1.57], // Tableau 3
    [0, 1.57], // Tableau 4
    [-1.57, 1.57], // Tableau 5
    [Math.PI, 1.57], // Tableau 20
    [Math.PI, 1.57], // Tableau 19
    [Math.PI, 1.57], // Tableau 18
    [Math.PI, 1.57], // Tableau 17
    [0, 1.57], // Tableau 24
    [0, 1.57], // Tableau 23
    [0, 1.57], // Tableau 22
    [0, 1.57], // Tableau 21
    [-1.57, 1.57], // Tableau 6
    [Math.PI, 1.57], // Tableau 28
    [Math.PI, 1.57], // Tableau 27
    [Math.PI, 1.57], // Tableau 26
    [Math.PI, 1.57], // Tableau 25
    [0, 1.57], // Tableau 32
    [0, 1.57], // Tableau 31
    [0, 1.57], // Tableau 30
    [0, 1.57], // Tableau 29
    [-1.57, 1.57], // Tableau 7
    [-1.57, 1.57], // Tableau 8
    [Math.PI, 1.57], // Tableau 36
    [Math.PI, 1.57], // Tableau 35
    [Math.PI, 1.57], // Tableau 34
    [Math.PI, 1.57], // Tableau 33
    [0, 1.57], // Tableau 40
    [0, 1.57], // Tableau 39
    [0, 1.57], // Tableau 38
    [0, 1.57], // Tableau 37
    [-1.57, 1.57], // Tableau 9
    [Math.PI, 1.57], // Tableau 10
    [Math.PI / 2, 1.57], // Tableau 11
    [Math.PI / 2, 1.57], // Tableau 12
    [Math.PI / 2, 1.57], // Tableau 13
    [Math.PI / 2, 1.57], // Tableau 14
    [Math.PI / 2, 1.57], // Tableau 15
    [Math.PI / 2, 1.57], // Tableau 16
  ];

  // Interface pour le listing des oeuvres //



  const listingInterfaceisOpen = useListing( ( state ) => state.setListingOpen )
  const handleMenuListingClick = () => {
    listingInterfaceisOpen()
    interfaceIsClosed()
  }

  return (
    <>
      {interfaces != "false" && mobile == false && (
        <div className="paintingInterface">
          <img
            src="/icon-menu-close.svg"
            className="paintingInterface__closeButton"
            onClick={() => {
              interfaceIsClosed();
              inc();
            }}
          ></img>
          <img
            src="/img/logo-horizontal.png"
            className="paintingInterface__monalisartLogo"
          ></img>
          <img
            src="/img/logo-horizontal.png"
            className="paintingInterface__monalisartLogoMediaQuery"
          ></img>
          <div className="total">
            <div className="paintingInterface__imageContainer">
              <InnerImageZoom
                src="./cat/unsplash.jpeg"
                zoomSrc="./cat/unsplash-large.jpeg"
                width={750}
                height={500}
                hasSpacer={true}
              />

              <p>
                <b>&copy; 2021 DANIEL NICKERSFIELD</b>- TOUS DROITS RESERVES.
                REPRODUCTION INTERDITE.
              </p>

            </div>
            <div className="paintingInterface__logoContainer">
              <img
                className="paintingInterface__logo"
                src="./Icons/logo-monalisart.svg"
              ></img>
            </div>
            <div className="paintingInterface__textContainer">
              <div className="paintingInterface__content">
                <div className="paintingInterface__logo--insideDiv">
                  <img
                    className="paintingInterface__logo--insideContent"
                    src="./Icons/logo-monalisart.svg"
                  ></img>
                </div>
                {/* <h1>{h1Elements[index]}</h1> */}
                <div className="paintingInterface__title">
                  <h1>Daniel Nickersfield</h1>
                  <div className="paitingInterface__boutons">
                    <img
                      className="img__bouton--liste"
                      src="./Icons/bouton-liste.svg"
                    ></img>
                    <img
                      className="img__bouton--acheter"
                      src="./Icons/bouton-acheter-v2.svg"
                      onClick={handleMenuListingClick}
                    ></img>
                  </div>
                </div>
                <h2>
                  2023 | <span className="span-left"></span> Lisa is a fu*king
                  capitalist ! <span className="span-right"></span>
                </h2>
                <p>
                  L'artiste a souhaité représenter la Joconde du XXIème siècle,{" "}
                  <br></br>entourée de marques , de logos, du capitalisme,
                  ect...
                </p>

                <div className="paintingInterface__infoContainer">
                  <div className="paintingInterface__infoContainer__part1">
                    <span>
                      <b>TECHNIQUE :</b> Collage
                    </span>
                    <hr className="hr__left"></hr>
                    <span>
                      <b>FORMAT :</b> 45 X 45 cm
                    </span>
                    <hr></hr>
                    <span>
                      <b>ESTIMATION :</b> 2,500,00 €
                    </span>
                    <hr></hr>
                    <span>
                      <b>ORIGINE :</b> France
                    </span>
                  </div>
                  <hr className="hr__center"></hr>
                  <div className="paintingInterface__infoContainer__part2">
                    <span>
                      <b>EN SAVOIR PLUS SUR L'ARTISTE</b>
                    </span>
                    <hr className="hr__right__savoir"></hr>
                    <span>
                      <b>Voir le site de l'artiste</b>
                    </span>
                    <hr className="hr__right"></hr>
                    <span>
                      <b>Voir la collection sur Binance</b>
                    </span>
                    <hr className="hr__right"></hr>
                    <span>
                      <b>Réseaux sociaux :</b> f i p{" "}
                    </span>
                  </div>
                </div>

                <div className="paintingInterface__mediaContainer">
                  <h2>MÉDIAS</h2>
                  <hr className="hr__media"></hr>
                  <div className="paintingInterface__mediaContainer--img ">
                    <div className="img__media">
                      <img
                        className="paintingInterface__imgMedia"
                        src="./img/m.png"
                      ></img>
                      <span className="text__media">
                        Interview vidéo de l'artiste<br></br>
                        Daniel Nickersfield dans le<br></br>
                        cadre de l'exposition<br></br>
                        Mona.Lis.Art
                      </span>
                    </div>
                    <hr className="hr__imgMedia"></hr>
                    <div className="img__media">
                      <img
                        className="paintingInterface__imgMedia"
                        src="./img/m.png"
                      ></img>
                      <span className="text__media">
                        Interview vidéo de l'artiste<br></br>
                        Daniel Nickersfield dans le<br></br>
                        cadre de l'exposition<br></br>
                        Mona.Lis.Art
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              mobile
                ? "paintingInterface__buttonContainer--mobile"
                : "paintingInterface__buttonContainer"
            }
          >
            <div>
              <img
                className="paintingInterface__nextButton"
                src="/Icons/icon-next-active.svg"
                onClick={() => {
                  increamentIndex();
                  if ( index + 1 === 40 ) {
                    setIndex( 0 );
                    cameraControlsRef.current.moveTo(
                      tabPos[0][0],
                      tabPos[0][1],
                      tabPos[0][2]
                    );
                    cameraControlsRef.current.rotateTo(
                      sidePos[0][0],
                      sidePos[0][1]
                    );
                  } else {
                    cameraControlsRef.current.moveTo(
                      tabPos[index + 1][0],
                      tabPos[index + 1][1],
                      tabPos[index + 1][2]
                    );
                    cameraControlsRef.current.rotateTo(
                      sidePos[index + 1][0],
                      sidePos[index + 1][1]
                    );
                  }
                }}
              ></img>
            </div>
            <div>
              <img
                src="/Icons/icon-previous-active.svg"
                className="paintingInterface__previousButton"
                onClick={() => {
                  decrementIndex();
                  if ( index - 1 === -1 ) {

                    setIndex( 40 );
                    cameraControlsRef.current.moveTo(
                      tabPos[39][0],
                      tabPos[39][1],
                      tabPos[39][2]
                    );
                    cameraControlsRef.current.rotateTo(
                      sidePos[39][0],
                      sidePos[39][1]
                    );
                  } else {
                    cameraControlsRef.current.moveTo(
                      tabPos[index - 1][0],
                      tabPos[index - 1][1],
                      tabPos[index - 1][2]
                    );
                    cameraControlsRef.current.rotateTo(
                      sidePos[index - 1][0],
                      sidePos[index - 1][1]
                    );
                  }
                }}
              ></img>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
