import { useProgress } from "@react-three/drei"
import { useEffect, useState } from "react"
import { useIsLandscape } from "../../utils/is_landscape"
import "../StartMenu/StartMenu.css"
export default function StartMenu( { started, onStarted } ) {
    const { active, progress, errors, item, loaded, total } = useProgress()

    const [isTotal, setIsTotal] = useState( false )

    useEffect( () => {
        if ( total === 21 && progress === 100 ) {
            setTimeout( () => {
                setIsTotal( true )
            }, 3100 )

        }
    }, [total, progress] )

    return <>
        {isTotal && 
            <div className="startMenu" style={started ? { display: 'none' } : { display: 'flex' }}>
                <div className="startMenu__overlay" >
                    <img className="startMenu__image" src="./img/artefakt.png"></img>
                    <div className="startMenu__text">
                        <p>This work is co-authored by Monalisart & Agarta</p>
                    </div>
                    <span onClick={onStarted} className="startMenu__bouton" >Entrez</span>
                </div>
            </div>
        }
    </>
}