/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.0.9 gallery_wip.gltf --transform -k -K
*/

import React, { useRef, useState } from 'react'
import { Html, useGLTF } from '@react-three/drei'

export default function Paintings() {

  const [hovered, setHovered] = useState( false );

  const handleHover = ( event ) => {
    setHovered( true );
  };

  const handleLeave = ( event ) => {
    setHovered( true );
  };
  const { nodes, materials } = useGLTF( '/gallery_wip-transformed.glb' )
  return <>
    <mesh name="Frame_20" geometry={nodes.Frame_20.geometry} material={materials.mat_frame_black} position={[9.35, 2.02, -5.67]} />
    <mesh name="Frame_19" geometry={nodes.Frame_19.geometry} material={materials.mat_frame_black} position={[6.35, 2.02, -5.67]} />
    <mesh name="Frame_27" geometry={nodes.Frame_27.geometry} material={materials.mat_frame_black} position={[6.35, 2.02, 1.83]} />
    <mesh name="Frame_28" geometry={nodes.Frame_28.geometry} material={materials.mat_frame_black} position={[9.35, 2.02, 1.83]} />
    <mesh name="Frame_36" geometry={nodes.Frame_36.geometry} material={materials.mat_frame_black} position={[9.35, 2.02, 9.33]} />
    <mesh name="Frame_35" geometry={nodes.Frame_35.geometry} material={materials.mat_frame_black} position={[6.35, 2.02, 9.33]} />
    <mesh name="Frame_21" geometry={nodes.Frame_21.geometry} material={materials.mat_frame_black} position={[9.35, 2.02, -5.18]} />
    <mesh name="Frame_03" geometry={nodes.Frame_03.geometry} material={materials.mat_frame_black} position={[4.1, 2.02, -18.9]} />
    <mesh name="Frame_30" geometry={nodes.Frame_30.geometry} material={materials.mat_frame_black} position={[6.35, 2.02, 2.32]} />
    <mesh name="Frame_29" geometry={nodes.Frame_29.geometry} material={materials.mat_frame_black} position={[9.35, 2.02, 2.32]} />
    <mesh name="Frame_37" geometry={nodes.Frame_37.geometry} material={materials.mat_frame_black} position={[9.35, 2.02, 9.82]} />
    <mesh name="Frame_38" geometry={nodes.Frame_38.geometry} material={materials.mat_frame_black} position={[6.35, 2.02, 9.82]} />
    <mesh name="Frame_05" geometry={nodes.Frame_05.geometry} material={materials.mat_frame_black} position={[15.31, 2.02, -8.28]} />
    <mesh name="Frame_06" geometry={nodes.Frame_06.geometry} material={materials.mat_frame_black} position={[15.31, 2.02, -2.38]} />
    <mesh name="Frame_08" geometry={nodes.Frame_08.geometry} material={materials.mat_frame_black} position={[15.31, 2.02, 6.54]} />
    <mesh name="Frame_07" geometry={nodes.Frame_07.geometry} material={materials.mat_frame_black} position={[15.31, 2.02, 2.08]} />
    <mesh name="Frame_09" geometry={nodes.Frame_09.geometry} material={materials.mat_frame_black} position={[15.31, 2.02, 13.53]} />
    <mesh name="Frame_16" geometry={nodes.Frame_16.geometry} material={materials.mat_frame_black} position={[-14.63, 2.02, -10.88]} />
    <mesh name="Frame_15" geometry={nodes.Frame_15.geometry} material={materials.mat_frame_black} position={[-14.63, 2.02, -5.74]} />
    <mesh name="Frame_12" geometry={nodes.Frame_12.geometry} material={materials.mat_frame_black} position={[-14.63, 2.02, 9.24]} />
    <mesh name="Frame_14" geometry={nodes.Frame_14.geometry} material={materials.mat_frame_black} position={[-14.63, 2.02, -0.52]} />
    <mesh name="Frame_11" geometry={nodes.Frame_11.geometry} material={materials.mat_frame_black} position={[-14.63, 2.02, 13.77]} />
    <mesh name="Frame_13" geometry={nodes.Frame_13.geometry} material={materials.mat_frame_black} position={[-14.63, 2.02, 4.62]} />
    <mesh name="Frame_10" geometry={nodes.Frame_10.geometry} material={materials.mat_frame_black} position={[0.35, 3.27, 17.04]} />
    <mesh name="Frame_18" geometry={nodes.Frame_18.geometry} material={materials.mat_frame_black} position={[-5.65, 2.02, -5.67]} />
    <mesh name="Frame_17" geometry={nodes.Frame_17.geometry} material={materials.mat_frame_black} position={[-8.65, 2.02, -5.67]} />
    <mesh name="Frame_25" geometry={nodes.Frame_25.geometry} material={materials.mat_frame_black} position={[-8.65, 2.02, 1.83]} />
    <mesh name="Frame_26" geometry={nodes.Frame_26.geometry} material={materials.mat_frame_black} position={[-5.65, 2.02, 1.83]} />
    <mesh name="Frame_34" geometry={nodes.Frame_34.geometry} material={materials.mat_frame_black} position={[-5.65, 2.02, 9.33]} />
    <mesh name="Frame_33" geometry={nodes.Frame_33.geometry} material={materials.mat_frame_black} position={[-8.65, 2.02, 9.33]} />
    <mesh name="Frame_23" geometry={nodes.Frame_23.geometry} material={materials.mat_frame_black} position={[-5.65, 2.02, -5.18]} />
    <mesh name="Frame_24" geometry={nodes.Frame_24.geometry} material={materials.mat_frame_black} position={[-8.65, 2.02, -5.18]} />
    <mesh name="Frame_32" geometry={nodes.Frame_32.geometry} material={materials.mat_frame_black} position={[-8.65, 2.02, 2.32]} />
    <mesh name="Frame_31" geometry={nodes.Frame_31.geometry} material={materials.mat_frame_black} position={[-5.65, 2.02, 2.32]} />
    <mesh name="Frame_39" geometry={nodes.Frame_39.geometry} material={materials.mat_frame_black} position={[-5.65, 2.02, 9.82]} />
    <mesh name="Frame_40" geometry={nodes.Frame_40.geometry} material={materials.mat_frame_black} position={[-8.65, 2.02, 9.82]} />
    <mesh name="Frame_02" geometry={nodes.Frame_02.geometry} material={materials.mat_frame_black} position={[-3.4, 2.02, -18.9]} />
    <mesh name="Frame_01" geometry={nodes.Frame_01.geometry} material={materials.mat_frame_black} position={[-10.9, 2.02, -18.9]} />
    <mesh name="Frame_04" geometry={nodes.Frame_04.geometry} material={materials.mat_frame_black} position={[11.6, 2.02, -18.9]} />
    <mesh name="Frame_22" geometry={nodes.Frame_22.geometry} material={materials.mat_frame_black} position={[6.35, 2.02, -5.18]} />

    <group
  
    >
      <mesh
        onPointerOver={handleHover}
        onPointerOut={handleLeave}
        onPointerDown={( e ) => e.stopPropagation()}
        name="Paintings_01" number={0} side='front' geometry={nodes.Paintings_01.geometry} material={materials.mat_paintings_baked_DIFF} position={[-10.9, 2.02, -18.91]}>
        {/* {hovered && (
        <Html position={[0, 1.7, 0]}>
            <img src='./hober.png'></img>
        </Html>
      )} */}
      </mesh>
      <mesh name="Paintings_02" number={1} side='front' geometry={nodes.Paintings_02.geometry} material={materials.mat_paintings_baked_DIFF} position={[-3.4, 2.02, -18.91]} />
      <mesh name="Paintings_03" number={2} side='front' geometry={nodes.Paintings_03.geometry} material={materials.mat_paintings_baked_DIFF} position={[4.1, 2.02, -18.91]} />
      <mesh name="Paintings_04" number={3} side='front' geometry={nodes.Paintings_04.geometry} material={materials.mat_paintings_baked_DIFF} position={[11.6, 2.02, -18.91]} />
      <mesh name="Paintings_05" number={4} side='left' geometry={nodes.Paintings_05.geometry} material={materials.mat_paintings_baked_DIFF} position={[15.32, 2.02, -8.28]} />
      <mesh name="Paintings_06" number={5} side='left' geometry={nodes.Paintings_06.geometry} material={materials.mat_paintings_baked_DIFF} position={[15.32, 2.02, -2.38]} />
      <mesh name="Paintings_07" number={13} side='left' geometry={nodes.Paintings_07.geometry} material={materials.mat_paintings_baked_DIFF} position={[15.32, 2.02, 2.08]} />
      <mesh name="Paintings_08" number={22} side='left' geometry={nodes.Paintings_08.geometry} material={materials.mat_paintings_baked_DIFF} position={[15.32, 2.02, 6.54]} />
      <mesh name="Paintings_09" number={23} side='left' geometry={nodes.Paintings_09.geometry} material={materials.mat_paintings_baked_DIFF} position={[15.32, 2.02, 13.53]} />
      <mesh name="Paintings_10" number={33} side='bigone' geometry={nodes.Paintings_10.geometry} material={materials.mat_paintings_baked_DIFF} position={[0.35, 3.27, 17.06]} />
      <mesh name="Paintings_11" number={34} side='right' geometry={nodes.Paintings_11.geometry} material={materials.mat_paintings_baked_DIFF} position={[-14.64, 2.02, 13.77]} />
      <mesh name="Paintings_12" number={35} side='right' geometry={nodes.Paintings_12.geometry} material={materials.mat_paintings_baked_DIFF} position={[-14.64, 2.02, 9.24]} />
      <mesh name="Paintings_13" number={36} side='right' geometry={nodes.Paintings_13.geometry} material={materials.mat_paintings_baked_DIFF} position={[-14.64, 2.02, 4.62]} />
      <mesh name="Paintings_14" number={37} side='right' geometry={nodes.Paintings_14.geometry} material={materials.mat_paintings_baked_DIFF} position={[-14.64, 2.02, -0.52]} />
      <mesh name="Paintings_15" number={38} side='right' geometry={nodes.Paintings_15.geometry} material={materials.mat_paintings_baked_DIFF} position={[-14.64, 2.02, -5.74]} />
      <mesh name="Paintings_16" number={39} side='right' geometry={nodes.Paintings_16.geometry} material={materials.mat_paintings_baked_DIFF} position={[-14.64, 2.02, -10.88]} />
      <mesh name="Paintings_17" number={8} side='back' geometry={nodes.Paintings_17.geometry} material={materials.mat_paintings_baked_DIFF} position={[-8.65, 2.02, -5.66]} />
      <mesh name="Paintings_18" number={7} side='back' geometry={nodes.Paintings_18.geometry} material={materials.mat_paintings_baked_DIFF} position={[-5.65, 2.02, -5.66]} />
      <mesh name="Paintings_19" number={6} side='back' geometry={nodes.Paintings_19.geometry} material={materials.mat_paintings_baked_DIFF} position={[6.35, 2.02, -5.66]} />
      <mesh name="Paintings_20" number={5} side='back' geometry={nodes.Paintings_20.geometry} material={materials.mat_paintings_baked_DIFF} position={[9.35, 2.02, -5.66]} />
      <mesh name="Paintings_21" number={12} side='front' geometry={nodes.Paintings_21.geometry} material={materials.mat_paintings_baked_DIFF} position={[9.35, 2.02, -5.19]} />
      <mesh name="Paintings_22" number={11} side='front' geometry={nodes.Paintings_22.geometry} material={materials.mat_paintings_baked_DIFF} position={[6.35, 2.02, -5.19]} />
      <mesh name="Paintings_23" number={10} side='front' geometry={nodes.Paintings_23.geometry} material={materials.mat_paintings_baked_DIFF} position={[-5.65, 2.02, -5.19]} />
      <mesh name="Paintings_24" number={9} side='front' geometry={nodes.Paintings_24.geometry} material={materials.mat_paintings_baked_DIFF} position={[-8.65, 2.02, -5.19]} />
      <mesh name="Paintings_25" number={17} side='back' geometry={nodes.Paintings_25.geometry} material={materials.mat_paintings_baked_DIFF} position={[-8.65, 2.02, 1.84]} />
      <mesh name="Paintings_26" number={16} side='back' geometry={nodes.Paintings_26.geometry} material={materials.mat_paintings_baked_DIFF} position={[-5.65, 2.02, 1.84]} />
      <mesh name="Paintings_27" number={15} side='back' geometry={nodes.Paintings_27.geometry} material={materials.mat_paintings_baked_DIFF} position={[6.35, 2.02, 1.84]} />
      <mesh name="Paintings_28" number={14} side='back' geometry={nodes.Paintings_28.geometry} material={materials.mat_paintings_baked_DIFF} position={[9.35, 2.02, 1.84]} />
      <mesh name="Paintings_29" number={21} side='front' geometry={nodes.Paintings_29.geometry} material={materials.mat_paintings_baked_DIFF} position={[9.35, 2.02, 2.31]} />
      <mesh name="Paintings_30" number={20} side='front' geometry={nodes.Paintings_30.geometry} material={materials.mat_paintings_baked_DIFF} position={[6.35, 2.02, 2.31]} />
      <mesh name="Paintings_31" number={19} side='front' geometry={nodes.Paintings_31.geometry} material={materials.mat_paintings_baked_DIFF} position={[-5.65, 2.02, 2.31]} />
      <mesh name="Paintings_32" number={18} side='front' geometry={nodes.Paintings_32.geometry} material={materials.mat_paintings_baked_DIFF} position={[-8.65, 2.02, 2.31]} />
      <mesh name="Paintings_33" number={27} side='back' geometry={nodes.Paintings_33.geometry} material={materials.mat_paintings_baked_DIFF} position={[-8.65, 2.02, 9.34]} />
      <mesh name="Paintings_34" number={26} side='back' geometry={nodes.Paintings_34.geometry} material={materials.mat_paintings_baked_DIFF} position={[-5.65, 2.02, 9.34]} />
      <mesh name="Paintings_35" number={25} side='back' geometry={nodes.Paintings_35.geometry} material={materials.mat_paintings_baked_DIFF} position={[6.35, 2.02, 9.34]} />
      <mesh name="Paintings_36" number={24} side='back' geometry={nodes.Paintings_36.geometry} material={materials.mat_paintings_baked_DIFF} position={[9.35, 2.02, 9.34]} />
      <mesh name="Paintings_37" number={31} side='front' geometry={nodes.Paintings_37.geometry} material={materials.mat_paintings_baked_DIFF} position={[9.35, 2.02, 9.81]} />
      <mesh name="Paintings_38" number={30} side='front' geometry={nodes.Paintings_38.geometry} material={materials.mat_paintings_baked_DIFF} position={[6.35, 2.02, 9.81]} />
      <mesh name="Paintings_39" number={29} side='front' geometry={nodes.Paintings_39.geometry} material={materials.mat_paintings_baked_DIFF} position={[-5.65, 2.02, 9.81]} />
      <mesh name="Paintings_40" number={28} side='front' geometry={nodes.Paintings_40.geometry} material={materials.mat_paintings_baked_DIFF} position={[-8.65, 2.02, 9.81]} />
    </group>

  </>
}